/* Fonts */
$roboto: 'Roboto', sans-serif;
/*
Light
Regular
Italic
Bold
Bold Italic
Black Italic
*/

$opensans: 'Open Sans', sans-serif;
/*
Light
Regular
*/

$oswald: 'Oswald', sans-serif;
/*
Light
Regular
*/

$light: 300;
$regular: 400;
$bold: 700;

/* Colours */
$blue: #39A7DF;
$blue-off: #2A80D1;
$blue-dark: #107299;
$page-heading-blue: #3397DA;
$grey: #999;
$grey-text: #666;
.footer {
    padding: {
        top: 50px;
        bottom: 40px;
    }
    font-size: 16px;
    font-family: $roboto;
    font-weight: $regular;

    .ctas {
        @include clearfix;
        text-align: center;

        .cta {
            width: 357px;
            text-align: left;
            float: left;
            margin: {
                right: 49px;
                bottom: 30px;
            }
            padding: {
                top: 10px;
                right: 20px;
                left: 20px;
                bottom: 20px;
            }

            &:nth-child(3) {
                margin-right: 0;
            }

            @media screen and (max-width: 1208px) {
                float: none;
                display: inline-block;
                margin: {
                    left: 20px;
                    right: 20px;
                }

                &:nth-child(3) {
                    margin: {
                        left: 20px;
                        right: 20px;
                    }
                }
            }

            @media screen and (max-width: 600px) {
                width: 100%;
                margin: {
                    left: 0px;
                    right: 0px;
                }

                &:nth-child(3) {
                    margin: {
                        left: 0px;
                        right: 0px;
                    }
                }
            }

            &--header {
                font-family: $oswald;
                font-weight: $light;
                font-size: 27px;
                margin-bottom: 10px;
            }

            p {
                font-family: $roboto;
            }
        }

        &.-blue {
            .cta {
                background-color: $blue-off;
                background-image: url('../img/svg/svg--logo-lanes.svg');
                background-position: 120% -100px;
                background-repeat: no-repeat;
                background-size: 240px 240px; 
                color: #fff;
            }

            p {
                width: 75%;
                font-weight: $light;
                font-size: 18px;
                float: left;
            }

            a {
                width: 47px;
                height: 47px;
                float: right;
                display: block;
                background-image: url('../img/svg/svg--arrow-right.svg');
                margin-top: 22px;
            }
        }

        &.-white {
            .cta {
                background-color: #fff;

                &--header {
                    color: $blue-dark;
                }

                &--image {
                    float: left;
                    width: 50%;

                    img {
                        width: 86%;
                    }
                }

                &--text {
                    width: 50%;
                    float: left;

                    p {
                        font-weight: $regular;
                        color: $grey;
                        font-size: 16px;
                    }

                    a {
                        text-decoration: none;
                        color: $blue-off;
                        font-weight: $bold;
                        margin-top: 20px;
                        display: block;
                    }
                }
            }
        }
    }

    .nav {
        text-align: center;
        margin-top: 40px;

        li {
            display: inline-block;
            text-align: center;
            
            padding: {
                left: 30px;
                right: 30px;
            }
            border-left: 1px solid #fff;

            &:first-child {
                border-left: 0;
            }

            a {
                color: #fff;
                text-decoration: none;
                font-weight: $light;
            }

            @media screen and (max-width: 585px) {
                display: block;
                border-left: 0;
                padding: 0;
                margin-bottom: 10px;
            }
        }
    }

    &--details {
        color: $blue;
        text-align: center;
        margin-top: 30px;

        a {
            color: $blue;
            text-decoration: none;
        }
    }
}
.news {

}

.news--mini {
    @include clearfix;

    border-bottom: 1px solid #ccc;
    padding: {
        top: 10px;
        bottom: 10px;
    }
    margin: {
        top: 10px;
        bottom: 10px;
    }

    .news--snippet {
        width: 74.440366972%;
        margin-left: 4%;
        float: right;

        a {
            color: $blue;
            text-decoration: none;
            font-weight: $bold;
            float: right;
            margin-top: 10px;
        }

        @media screen and (max-width: 760px) {
            width: 100%;
            margin-left: 0;
        }
    }

    .news--image {
        width: 21.55963302752294%;
        float: left;
        margin-bottom: 10px;

        @media screen and (max-width: 760px) {
            width: auto;
        }

        img {
            width: 100%;
        }
    }
}

    .news--title {
        color: $page-heading-blue;
        font-size: 24px;
        font-weight: $bold;
    }
body {
    background-color: #000;
}

.container {
    width: 100%;
    max-width: 1210px;
    padding: 0 20px;
    margin: 0 auto;
}

.hero {
    position: relative;
    background-size: cover;
    background-position: top center;

    &.-home {
        height: 600px;
        background-position: top right;

        @media screen and (max-width: 700px) {
            height: 400px;
        }

        h1 {
            color: #fff;
            letter-spacing: 8px;
            font-size: 44px;
            padding-top: 100px;
            padding-left: 40px;
            text-shadow: 0px 0px 8px #000;

            @media screen and (max-width: 700px) {
                font-size: 30px;
            }

            @media screen and (max-width: 425px) {
                font-size: 25px;
            }
        }

        .quick-links {
            width: 50px;
            height: 50px;
            display: block;
            background-image: url('../img/svg/svg--arrow-down.svg');
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%);
            margin-top: 50px;

            @media screen and (max-width: 524px) {
                margin-top: 100px;
            }
        }

        .pdf-links {
            margin-top: 30px;
            margin-left: 40px;

            @media screen and (max-width: 524px) {
                margin-top: 15px;
            }

            a {
                width: 220px;
                display: block;
                margin-bottom: 10px;
                padding: 10px;
                background-color: #000;
                background-image: url('../img/svg/svg--arrow-right.svg');
                background-repeat: no-repeat;
                background-position: 95% center;
                background-size: 23px 23px;
                font-family: $oswald;
                text-transform: uppercase;
                font-size: 18px;
                font-weight: $light;
                color: #fff;
                text-decoration: none;

                @media screen and (max-width: 700px) {
                    display: inline-block;
                }
            }
        }
    }

    &.-page {
        height: 435px;
        z-index: 1;

        .mini-nav {
            width: 100%;
            max-width: 330px;
            height: 236px;
            float: right;
            background-color: rgba(0, 0, 0, .75);
            padding: 20px;
            color: $blue;
            font-family: $oswald;
            font-size: 22px;
            font-weight: $regular;

            ul {
                margin-top: 10px;

                li {
                    margin-bottom: 5px;

                    a {
                        text-decoration: none;
                        color: #fff;
                        font-family: $roboto;
                        font-weight: $light;
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

.page-title {
    padding: {
        top: 17px;
        bottom: 15px;
    }
    border-top: 15px solid #fff;
    color: $blue;
    font-family: $oswald;
    font-weight: $light;
    font-size: 33px;
}

.content {
    @include clearfix;

    position: relative;
    background-color: #fff;
    padding: {
        top: 35px;
        right: 40px;
        bottom: 40px;
        left: 40px;
    }
    margin-top: -100px;
    font-family: $roboto;
    font-weight: $regular;
    z-index: 2;

    h2 {
        color: $page-heading-blue;
        font-size: 24px;
        font-weight: $bold;
    }

    p {
        color: $grey-text;
        font-size: 18px;
        margin-top: 15px;
    }

    &--split {
        float: left;
        @media screen and (max-width: 990px) {
            float: none;
        }

        &.-main {
            width: 61.697247706%;
            margin-right: 5%;

            @media screen and (max-width: 990px) {
                width: 100%;
                margin-right: 0;
            }
        }

        &.-side {
            width: 33.30275229357798%;

            @media screen and (max-width: 990px) {
                width: 100%;
                max-width: 363px;
                margin: 20px auto 0;
            }

            img {
                margin-bottom: 20px;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }

            .carousel {
                img {
                    margin-bottom: 0;
                }
            }
        }
    }

    .image-row {
        @include clearfix;

        margin: {
            top: 30px;
            bottom: 10px;
        }

        @media screen and (max-width: 1209px) {
            max-width: 330px;
            margin: {
                left: auto;
                right: auto;
            }
        }

        img {
            width: 100%;
            max-width: 330px;
            float: left;
            margin: {
                right: 50px;
                bottom: 20px;                
            }

            &:last-of-type {
                margin-right: 0;    
            }
        }
    }
}
.address,
.directions {
    width: 50%;
    padding-right: 40px;
    float: left;

    @media screen and (max-width: 745px) {
        width: 100%;
        padding-right: 0;
    }

    p {
        font-family: $roboto;
        font-weight: $light;
        font-size: 22px;
        color: $blue;

        strong {
            font-weight: $bold;
        }

        a {
            text-decoration: none;
            color: $blue;
        }
    }
}

.map {
    width: 100%;
    height: 436px; 
    margin-top: 30px;
}
.header {
    @include clearfix;

    font-family: $oswald;
    font-weight: $light;
    color: #fff;

    .container {
        padding: {
            top: 20px;
            bottom: 20px;
        }
        background-image: url('../img/svg/svg--logo-lanes.svg');
        background-position: right top;
        background-repeat: no-repeat;
        background-size: 380px 260px;

        @media screen and (max-width: 840px) {
            background-image: none;
        }
    }

    a {
        color: #fff;
        text-decoration: none;
    }

    &--logo {
        width: 318px;
        height: 92px;
        background-image: url('../img/svg/svg--logo.svg');
        background-size: contain;
        background-repeat: no-repeat;
        float: left;
        margin-right: 60px;

        @media screen and (max-width: 1000px) {
            width: 100%;
            max-width: auto;
            margin: 0 auto;
            background-position: center;
        }
    }
    
    &--contact {
        @include clearfix;
        padding-top: 25px;

        @media screen and (max-width: 1000px) {
            width: 463px;
            margin: {
                top: 100px;
                right: auto;
                bottom: 20px;
                left: auto;
            }
            display: block;
        }

        @media screen and (max-width: 503px) {
            width: 228px;
        }

        .phone,
        .email {
            font-size: 25px;
            float: left;
        }

        .phone {
            margin-right: 30px;

            @media screen and (max-width: 503px) {
                margin: {
                    right: 0px;
                    left: 12px;
                    bottom: 15px;
                }
            }
        }

        .icon--phone,
        .icon--email {
            margin-right: 10px;
            transform: translateY(6px);
        }
    }

    .nav {
        clear: both;

        ul {

            @media screen and (max-width: 1000px) {
                display: none;
                text-align: center;
                
                &.show {
                    display: block;
                }
            }
        }

            li {
                display: inline-block;
                margin-right: 20px;
                font-size: 18px;

                @media screen and (max-width: 1000px) {
                    display: block;
                    text-align: center;
                    margin-right: 0;
                    margin-top: 10px;
                    margin-bottom: 20px;
                }

                &:nth-child(1n+9) {
                    transform: translateY(7px);
                    margin-right: 5px;
                    @media screen and (max-width: 1000px) {
                        display: inline-block;
                    }
                }

                &:nth-child(8) {
                    margin-right: 40px;

                    @media screen and (max-width: 1000px) {
                        margin-right: 0;
                    }
                }
            }
    }
}

.burger {
    display: none;

    @media screen and (max-width: 1000px) {
        width: 100%;
        display: inline-block;
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: $oswald;
        font-weight: $light;
        font-size: 20px;
        text-align: center;
        color: #fff;

        &:hover {
            cursor: pointer;
        }
    }
}

.icon--phone {
    width: 35px;
    height: 35px;
    display: inline-block;
    background-image: url('../img/svg/svg--icon-phone.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

.icon--email {
    width: 33px;
    height: 33px;
    display: inline-block;
    background-image: url('../img/svg/svg--icon-email.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

.icon--twitter {
    width: 31px;
    height: 31px;
    display: inline-block;
    background-image: url('../img/svg/svg--icon-twitter.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

.icon--linkedin {
    width: 31px;
    height: 31px;
    display: inline-block;
    background-image: url('../img/svg/svg--icon-linkedin.svg');
    background-size: contain;
    background-repeat: no-repeat;
}

.icon--facebook {
    width: 31px;
    height: 31px;
    display: inline-block;
    background-image: url('../img/svg/svg--icon-facebook.svg');
    background-size: contain;
    background-repeat: no-repeat;
}